import "./SpeechToText.css";
import React from "react";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const SpeechToText = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true });

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  return (
    <div>
      <CardMedia
        sx={{ height: 140 }}
        image="https://images.unsplash.com/photo-1464226184884-fa280b87c399?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
      <br />
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          gutterBottom
        >
          <b>Hvad har du spist i dag?</b>
        </Typography>
        <Typography gutterBottom>
          {transcript ? (
            transcript
          ) : (
            <Typography color="textDisabled">
              "f.eks. havregryn, kaffe, sandwich"
            </Typography>
          )}
        </Typography>
      </Container>
      <div className="flexbox">
        <Fab
          style={{ marginRight: "10px" }}
          color={listening ? "error" : "primary"}
          aria-label="record"
          onTouchStart={startListening}
          onMouseDown={startListening}
          onTouchEnd={SpeechRecognition.stopListening}
          onMouseUp={SpeechRecognition.stopListening}
          size="large"
        >
          {listening ? <MicOffIcon /> : <MicIcon />}
        </Fab>
        <Fab
          color="primary"
          aria-label="clear"
          onClick={resetTranscript}
          size="large"
        >
          <RestartAltIcon />
        </Fab>
      </div>
    </div>
  );
};

export default SpeechToText;
